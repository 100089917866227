// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutme-js": () => import("./../../../src/pages/aboutme.js" /* webpackChunkName: "component---src-pages-aboutme-js" */),
  "component---src-pages-autoteams-js": () => import("./../../../src/pages/autoteams.js" /* webpackChunkName: "component---src-pages-autoteams-js" */),
  "component---src-pages-crmaccounts-js": () => import("./../../../src/pages/crmaccounts.js" /* webpackChunkName: "component---src-pages-crmaccounts-js" */),
  "component---src-pages-crmmedia-js": () => import("./../../../src/pages/crmmedia.js" /* webpackChunkName: "component---src-pages-crmmedia-js" */),
  "component---src-pages-grading-js": () => import("./../../../src/pages/grading.js" /* webpackChunkName: "component---src-pages-grading-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learningpathway-js": () => import("./../../../src/pages/learningpathway.js" /* webpackChunkName: "component---src-pages-learningpathway-js" */),
  "component---src-pages-myinsights-js": () => import("./../../../src/pages/myinsights.js" /* webpackChunkName: "component---src-pages-myinsights-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

